import { APIFilter } from '@/utils/api'

export default {
  async insert (Vue, formData, idcliente, idsistema, idclientePotencial) {
    let idclienteIns = idcliente
    if (idsistema) {
      const apiFilter = new APIFilter()
      apiFilter.addExact('idsistema', idsistema)
      const resp = await Vue.$api.call('sistema.select', { filter: apiFilter })
      idclienteIns = resp.data.result.dataset[0].idcliente
    }
    const resp = await Vue.$api.call(
      'clienteTelefono.insert',
      {
        values: {
          idcliente: idclienteIns,
          idcliente_potencial: idclientePotencial,
          idttelefono: formData.idttelefono,
          telefono: formData.telefono,
          horario: formData.horario,
          observaciones: formData.observaciones,
          email: formData.email,
          nombre: formData.nombre,
          email_notifica_admon: formData.email_notifica_admon,
          email_notifica_comercial: formData.email_notifica_comercial,
          email_notifica_sat: formData.email_notifica_sat,
          para_todos_los_sistemas: idsistema ? false : formData.para_todos_los_sistemas,
        },
      }
    )
    if (idsistema) {
      await Vue.$api.call(
        'sistemaTelefono.insert',
        {
          values: {
            idcliente_telefono: resp.data.result.dataset[0].idcliente_telefono,
            idsistema,
            orden: 1,
            orden_cra: formData.orden_cra,
            codigo_cra: formData.codigo_cra,
            codigo_coaccion_cra: formData.codigo_coaccion_cra,
            es_responsable_propiedad: false,
            acceso_web: false,
          },
        }
      )
    }
    return resp.data.result.dataset[0]
  },
}
